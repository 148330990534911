import React, { useState, Fragment, useRef } from "react";
import { TelegramIcon, TwitterIcon } from "../../component/Icons/Icon";
import Download from "../../component/Button/Download";
import { UnrealIcon } from "../../component/Icons/Icon";
import { CryptoGptIcon } from "../../component/Icons/Icon";

const Index = (props) => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative wrapper__min-height-100 d-flex flex-wrap">
          <img src="./../images/image 2.png" className="images__bg-head" alt="" />
          <div className="wrapper__sosmed position-relative z-2 d-none d-md-block w-100">
            <div className="container">
              <div className="wrapSosmed">
                <a href="https://twitter.com/themafiosocom">
                  <TwitterIcon />
                </a>
                <a href="https://t.me/themafiosochat">
                  <TelegramIcon />
                </a>
              </div>
            </div>
          </div>

          <div className="text-center d-md-none position-relative z-2 ">
            <div className="container py-5">
              <img src="./../images/bianco@4x 1.png" alt="" />
            </div>
          </div>

          <section className="position-relative py-5 z-2 w-100 align-self-end">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-lg-8 text-center text-md-left">
                  <img src="./../images/bianco@4x 1.png" className="mb-4 d-none d-md-block" alt="" />
                  <h1 className="semi-bold font__size--70 text__70-1024 text__70-md text__border-yellow mb-3">Let The Games Begin</h1>
                  <p className="semi-bold font__size--20 text__20-1024 color__white">Set in a gang-riddled 1920’s New York City, The Mafioso is a MMO filled with ruthless strategy, betrayal, triumph, and most importantly, hard work. </p>
                  <p className="semi-bold font__size--20 text__20-1024 color__white mb-4">Complete your first mission to simulate earn on your collected behavioural data.</p>

                  <div className="mt-3 d-flex flex-wrap justify-content-center justify-content-md-start">
                    <div className="mr-md-5 mr-mb-0 mb-3">
                      <Download url={'https://portal.furioos.com/share/2RRQ6LdXqdpiNM9Jq'} name={"Play Online"} />
                    </div>
                    <div>
                      <Download url={'https://themafioso.b-cdn.net/demo.zip'} name={"Download"} />
                    </div>
                  </div>

                  <div className="mb-5 mt-2 color__white font__size--10">
                    * download for Windows only
                  </div>

                  <div className="d-md-flex align-items-center text-center text-md-left">
                    <h5 className="mb-md-0 mb-3 semi-bold font__size--20 text__20-1024 color__white">Built on:</h5>
                    <div className="d-flex justify-content-lg-start justify-content-center align-items-center ml-3">
                      <div className="mr-3">
                        <div className="svg__responive one">
                          <UnrealIcon />
                        </div>
                      </div>
                      <div className="svg__responive two">
                        <CryptoGptIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div >
    </Fragment >
  );
};

export default Index;
