import React from 'react'

const Download = (props) => {
    const downloadFile = () => {
        const fileUrl = props.url;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.setAttribute('download', 'file_name');
        document.body.appendChild(link);
        link.click();
    }
    return (
        <button className='btn__download color__white bold font__size--18 text__18-1024' onClick={downloadFile}>{props.name}</button>
    )
}

export default Download
